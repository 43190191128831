/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'

import Header from "./header"
import Footer from './footer'
import "./layout.css"
import "animate.css/animate.min.css";
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  scroll-behavior: smooth;
`;

const Layout = ({ children }) => {

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
    <Wrapper>
      <Header />
      <main>{children}</main>
      <Footer />
    </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
