import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

const Drawer = styled(motion.div)`
    height: 90vh;
    width: 80vw;
    transform: translateX(-100%);
    background-color: #fff;
    position: fixed;
    z-index: 100;

    a {
        text-decoration: none;
        color: rgba(0, 0, 0, .8);
    }

    ul {
        list-style: none;

        li {
            font-size: 1.8rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-transform: uppercase;
            display: block;
            font-family: 'Poppins', sans-serif;
            padding: 2rem 3rem;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, .3);
            }

            @media (orientation: landscape ) {
                padding: 1rem 3rem;
            }
        }
    }
`;

const DarkBackground = styled(motion.div)`
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .7);
    opacity: 1;
    position: fixed;
    z-index: 90;
`;

const Sidebar = (props) => {
    let animateProps = props.open ? { translateX: 0 } : { translateX: '-100%' };
    return (
        <>
            <DarkBackground animate={props.open ? { opacity: 1, zIndex: 90 } : { opacity: 0, zIndex: -1 }} initial={false} />
                <Drawer animate={animateProps} initial={false}>
                    <nav>
                        <ul>
                            <li><a href="#welcome" onClick={props.closeHandler}> Welcome </a></li>
                            <li><a href="#services" onClick={props.closeHandler}>Services</a></li>
                            <li><a href="#slogan" onClick={props.closeHandler}>   About   </a></li>
                            <li><a href="#kudos" onClick={props.closeHandler}>   Kudos   </a></li>
                            <li><a href="#contact" onClick={props.closeHandler}> Contact </a></li>
                        </ul>
                    </nav>
                </Drawer>
        </>
        )
    }
    
export default Sidebar;